var _a, _b;
import { __assign, __awaiter, __generator } from "tslib";
import { debounce } from '@ekuaibao/lib/lib/lib-util';
import { app as api } from '@ekuaibao/whispered';
import messageCenter from '@ekuaibao/lib/lib/message-center';
import loadable from '@loadable/component';
function loadablePlus(loadFn, names) {
    var OO = loadable(loadFn);
    if (!names) {
        return OO;
    }
    names.forEach(function (name) {
        OO[name] = loadable(function () { return loadFn().then(function (module) { return ({ default: module[name] }); }); });
    });
    return OO;
}
function listenResize(app) {
    var f = debounce(function (layoutFirstObj) {
        var _a = document.body, clientWidth = _a.clientWidth, clientHeight = _a.clientHeight;
        var size = { x: clientWidth, y: clientHeight };
        var proxySize = __assign(__assign({}, size), layoutFirstObj);
        app.store.dispatch('@layout/windowSizeChange')(proxySize);
    }, 500);
    f({ isFirstLocal: true });
    var timer = undefined;
    window.onresize = function (_) {
        if (!timer) {
            timer = window.requestAnimationFrame(function () {
                timer = undefined;
                f({ isFirstResize: true });
            });
        }
    };
}
function handleRefresh(params) {
    api.dispatchO(function () { return ({ type: '@@redux/INIT' }); });
    setTimeout(function () {
        var corpId = params.corpId, accessToken = params.accessToken, userId = params.userId, deviceType = params.deviceType, platform = params.platform, url = params.url;
        var URL = url + "web/mc.html?";
        var href = URL + "userId=" + encodeURIComponent(userId) + "&corpId=" + encodeURIComponent(corpId) + "&accessToken=" + encodeURIComponent(accessToken) + "&deviceType=" + encodeURIComponent(deviceType) + "&platform=" + platform;
        api.emit('@vendor:open:link', href);
    }, 4);
}
export var Universal_Unique_Key = 'layout5.pc';
export default [
    {
        id: '@layout5',
        dependencies: ['@common'],
        path: '/',
        onload: function () { return import('./layout-basic'); },
        onfirst: function (app) { return __awaiter(void 0, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, app.store.dynamic('@layout5', function () { return import('./layout5.store'); }).load('@layout5')];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, app.store.dynamic('@layout', function () { return import('./layout.store'); }).load('@layout')];
                    case 2:
                        _b.sent();
                        return [4 /*yield*/, app.store
                                .dynamic('@marketADBanner', function () { return import('./marketBanner.store'); })
                                .load('@marketADBanner')];
                    case 3:
                        _b.sent();
                        api.invokeService('@auth-manage:get:base:staff');
                        api.store.dispatch('@marketADBanner/getBoxSetting')();
                        (window.IS_SMG ||
                            window.__PLANTFORM__ === 'APP' || ((_a = window.PLATFORMINFO) === null || _a === void 0 ? void 0 : _a.thirdPartSwithCorp)) &&
                            api.invokeService('@corporation-info:get:corporation:and:count');
                        listenResize(app);
                        return [2 /*return*/];
                }
            });
        }); },
        intoMC: function (params) {
            handleRefresh(params);
        },
        onready: function () {
            api.on('@layout:open:link', function (href) {
                api.store.dispatch('@layout/displayLayer')({ display: true, href: href });
            });
            api.history.listen(function () { return api.store.dispatch('@layout/SyncMenu4RouteChange')(); });
            api.history.listen(function () { return api.store.dispatch('@layout5/SyncMenu4RouteChange')(); });
        },
        'active:menu': function (id) {
            // @ts-ignore
            api.store.dispatch('@layout5/activeMenu')(id);
        },
        'refresh:menu:data': function (quiet) {
            if (quiet === void 0) { quiet = false; }
            // @ts-ignore
            api.store.dispatch('@layout5/getMenuData')(undefined, quiet);
        },
        'get:menu:data': function () {
            // @ts-ignore
            return api.store.states['@layout5'].mainMenus;
        },
        'show:mask': function () {
            // @ts-ignore
            api.store.dispatch('@layout5/showMask')();
        },
        'hide:mask': function () {
            // @ts-ignore
            api.store.dispatch('@layout5/hideMask')();
        },
        getNewExtensionTip: function () {
            return api.store.dispatch('@layout5/getNewExtensionTip')();
        },
        updateNewExtension: function () {
            return api.store.dispatch('@layout5/updateNewExtension')();
        },
        changeImmersive: function (isInImmersive) {
            setTimeout(function () {
                messageCenter.emit('refreshRender');
                window.dispatchEvent(new CustomEvent('resize'));
            }, 500);
            api.store.dispatch('@layout5/changeImmersive')(isInImmersive);
        },
    },
    {
        point: '@@layers',
        prefix: '@@system',
        onload: function () { return import('./remote-layers'); },
    },
    {
        point: '@@layers',
        prefix: '@layout5',
        onload: function () { return import('./layers'); },
    },
    {
        id: '@thirdResources',
        ref: '/',
        path: '/third-resource/:id',
        onload: function () { return import('./third-resources'); },
    },
    {
        path: '/mc-error',
        ref: '/',
        onload: function () { return import('./mc-error'); },
    },
    {
        path: '/widget-editor/:cardid/:appId',
        ref: '/',
        onload: function () { return import('./ExpansionCenterIframe'); },
    },
    {
        path: '/expense-center-new/:router?/:menu?/:status?',
        ref: '/',
        onload: function () { return import('./ExpansionCenterIframe'); },
    },
    {
        path: '/expense-center-new',
        ref: '/',
        onload: function () { return import('./ExpansionCenterIframe'); },
    },
    {
        path: '/scene-template-init/:router?/:menu?',
        ref: '/',
        onload: function () { return import('./SceneTemplateInitIframe'); },
    },
    {
        path: '/scene-template-init',
        ref: '/',
        onload: function () { return import('./SceneTemplateInitIframe'); },
    },
    (_a = {
            resource: '@layout5',
            value: (_b = {},
                _b['elements/ContentBody'] = loadablePlus(function () { return import('./elements/ContentBody'); }, [
                    'ContentBody',
                    'ContentHeader',
                    'ContentV2Header',
                    'ContentLeft',
                    'ContentRight',
                    'DraggleLayout',
                ]),
                _b)
        },
        _a['elements/ImmersiveIcon'] = loadable(function () { return import('./elements/ImmersiveIcon'); }),
        _a['elements/DraggleLayout'] = loadable(function () { return import('./elements/DraggleLayout'); }),
        _a),
];
